<template>
  <slot :edit-finance="editFinance">
    <OsButton
      class="edit-finance__button"
      v-bind="$attrs"
      aria-label="Edit Finance"
      @click.prevent="editFinance"
    >
      Edit Finance
    </OsButton>
  </slot>
</template>

<script lang="ts">
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { useSettingsStore } from '~/stores/settingsStore'

export default defineComponent({
  name: 'FinanceEditButton',

  emits: ['click'],

  setup(props, { emit }) {
    const settingsStore = useSettingsStore()
    const financeCriteriaStore = useFinanceCriteriaStore()

    const editFinance = () => {
      settingsStore.updateFinanceMode(true)
      financeCriteriaStore.hasAccepted = true

      emit('click')
    }

    return {
      editFinance
    }
  }
})
</script>

<style scoped lang="scss">
.edit-finance__button {
  background-color: $grey--lightest;
  border: none;
  width: 100%;
}
</style>
